function CartApi(api_host) {
    this.api_host = api_host || location.origin;
}

CartApi.prototype = {
    create: function(data, callback, errorCallback) {
        var url = this.api_host + "/carts/add";
        
        var d = new Date();
        var created_at = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours().toString().padStart(2, '0') + ":" + d.getMinutes().toString().padStart(2, '0') + ":" + d.getSeconds().toString().padStart(2, '0');

        var params = { cart_name: data.cart_name, cart_note: data.cart_note, created_at: created_at };
        $.post(url, params, callback).fail(errorCallback);
    },

    submit: function(data, callback, errorCallback) {
        var url = this.api_host + "/carts/submit";

        var d = new Date();
        var submitted_at = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

        var params = { shipping_details: data.shipping_details, billing_details: data.billing_details, submitted_at: submitted_at };
        $.post(url, params, callback).fail(errorCallback);
    },

    updateCartTitle: function(data, cart_id, callback, errorCallback) {
        var url = this.api_host + "/carts/" + cart_id + "/update";
        var params = {
            cart_name: data.cart_name,
            cart_note: data.cart_note,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorCallback);
    },

    deleteCartItem: function(cart_id, callback, errorCallback) {
        var url = this.api_host + "/carts/" + cart_id + "/delete";
        var params = {
            _method: "DELETE",
            cart_id: cart_id
        };

        $.post(url, params, callback).fail(errorCallback);
    },

    updateItemName: function(data, cart_item_id, callback, errorCallback) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-item-name";
        var params = {
            item_name: data.item_name,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorCallback);
    },

    getAll: function(successCallback, errorCallback) {
        var url = this.api_host + "/carts";
        $.get(url, successCallback).fail(errorCallback);
    },

    activate: function(cart_id, successCallback, errorCallback) {
        var url = this.api_host + "/carts/" + cart_id + "/activate";
        var parameters = {
            _method: "PUT"
        };

        $.post(url, parameters,  successCallback).fail(errorCallback);
    }
};